/** ******************************************************************************
LOS HTTP Client Wrapper
 * Package Root - import this!
 * Structures the REST API client. Client is organized primarily by entity,
 * though there are a handful of top level functions like `isAuthenticated`
 ******************************************************************************* */

import type { Auth } from "firebase/auth";

import updateApplicantNameAndDob from "#api.los/applicant.updateNameAndDOB";
import verifyIdentity from "#api.los/applicant.verifyIdentity";
import createChargerPricing from "#api.los/application.createChargerPricing";
import getChargerEligibility from "#api.los/application.getChargerEligibility";
import postChargerDecision from "#api.los/application.postCharger";
import postPrelimGAP from "#api.los/application.postPrelimGAP";
import getBusiness from "#api.los/business.get";
import upsertBusinessInfo from "#api.los/business.info.upsert";
import upsertBusinessOwnership from "#api.los/business.ownership.upsert";
import createTaskDocUploadLink from "#api.los/documents.createTaskDocUploadLink";
import getAllVehicleMakes from "#api.los/models.getAllVehicleMakes";
import setTaskAsPending from "#api.los/task.setTaskAsPending";
import setTasksToFinalized from "#api.los/task.setTasksToReview";
import subscribeEmail from "#api.los/tracking.subscribeEmail";
import trackOfferConfirmed from "#api.los/tracking.trackOfferConfirmed";
import addUserToExperiment from "#api.los/user.addToExperiment";
import trackExperimentEvent from "#api.los/user.trackExperimentEvent";
import { getAuth } from "#auth/utils";
import { stack } from "#util/env";

import getApplicant from "./applicant.get";
import cancelApplication from "./application.cancelApplication";
import confirmOffer from "./application.confirmOffer";
import createApplication from "./application.createApplication";
import createChargerEstimate from "./application.createChargerEstimate";
import createDirectOffer from "./application.createDirectOffer";
import createDraft from "./application.createDraft";
import emailOffer from "./application.emailOffer";
import getApplication from "./application.getActive";
import getAddons from "./application.getAddons";
import getApplications from "./application.getAll";
import getDealershipInfo from "./application.getDealershipInfo";
import getDocusignLink from "./application.getDocusignLink";
import getHardCredit from "./application.getHardCredit";
import getLimits from "./application.getLimits";
import getOffers from "./application.getOffers";
import getPrequal from "./application.getPrequal";
import getSelectedOffer from "./application.getSelectedOffer";
import getSoftCredit from "./application.getSoftCredit";
import getTasks from "./application.getTasks";
import cancelAutopay from "./application.payments.cancelAutopay";
import createAutopay from "./application.payments.createAutopay";
import getAutopay from "./application.payments.getAutopay";
import postAutopayPreference from "./application.payments.postAutopayPreference";
import updateAutopay from "./application.payments.updateAutopay";
import postChargerInstallDecision from "./application.postChargerInstallDecision";
import postDealershipInfo from "./application.postDealershipInfo";
import postGAP from "./application.postGAP";
import postWarranty from "./application.postWarranty";
import selectDirectOffer from "./application.selectDirectOffer";
import selectRefiOffer from "./application.selectRefiOffer";
import updateApplicant from "./application.updateApplicant";
import updateDealershipInfo from "./application.updateDealershipInfo";
import updateTerms from "./application.updateTerms";
import updateUnassociatedVehicle from "./application.updateUnassociatedVehicle";
import createVehicle from "./application.vehicle.create";
import updateVehicle from "./application.vehicle.update";
import upsertPrevLoan from "./application.vehicle.upsertPrevLoan";
import postWaitlist from "./application.waitlist";
import getCalculatorConfig from "./calculator.get";
import createOptionalUploadLink from "./document.createOptionalUploadLink";
import getDocuments from "./document.getDocuments";
import getUploadLink from "./document.getUploadLink";
import updateUploaded from "./document.updateUploaded";
import endSession from "./incomeVerification.endSession";
import getPlaidToken from "./incomeVerification.getPlaidToken";
import startSession from "./incomeVerification.startSession";
import initializePage from "./initializePage";
import getAllVehicleProfilesForMake from "./models.getAllUVCsForMake";
import isFinanceSupported from "./models.isFinanceSupported";
import search from "./models.search";
import buildOffer from "./offer.build";
import getStates from "./states";
import addEmailToWaitList from "./states.addEmailToWaitList";
import DEPRECATEDCompleteDocusign from "./task.DEPRECATED.CompleteDocusign";
import trackEvent from "./tracking.post";
import trackBudgetUser from "./user.budget.put";
import createAddress from "./user.createAddress";
import getUser from "./user.get";
import initUser from "./user.init";
import registerUser from "./user.register";
import resendCode from "./user.resendCode";
import updateAddressDetails from "./user.updateAddressDetails";
import updateAttribution from "./user.updateAttribution";
import updateDriversLicense from "./user.updateDriversLicense";
import updateEmail from "./user.updateEmail";
import updateNameAndDob from "./user.updateNameAndDob";
import updatePhone from "./user.updatePhone";
import updateReferrer from "./user.updateReferrer";
import updateSsn from "./user.updateSsn";
import verifyEmail from "./user.verifyEmail";
import verifyWithCode from "./user.verifyWithCode";
import compareVins from "./vehicle.compareVins";
import getVehicle from "./vehicle.get";
import getVehicleBasic from "./vehicleBasic.get";

const authKey = Symbol("auth");
const tokenKey = Symbol("token");

interface APIClientCommon {
  [authKey]: undefined | Auth;
  [tokenKey]: undefined | string;
  isAuthenticated: () => Promise<boolean>;
  getToken: () => undefined | string;
  logOut: () => void;
}

/**
 * Structures the API client
 * Organized around entity, e.g. "user", then specific action
 * e.g. "update email", so `client.user.updateEmail`
 */
const LOSClientCommon: APIClientCommon = {
  [authKey]: undefined,
  [tokenKey]: undefined,
  /** Checks to see if the user is logged in
   * Note that this will return true even if
   * the user's session has expired.
   * MPR, 2022/8/19: This could concievably live under "user" but
   * given that it will be one of the most utilized client methods,
   * I favored discoverability over consistency with "register"
   */
  isAuthenticated: async () => {
    if (LOSClient[tokenKey]) {
      return true;
    }
    const auth = await getAuth();
    const token = await auth.currentUser?.getIdToken();
    LOSClient[authKey] = auth;
    LOSClient[tokenKey] = token;
    return !!token;
  },
  getToken: () => {
    /* MPR, 2022/8/19: note that we do _not_ invoke isAuthenticated here.
     * it is the responsibility of the consumer to ensure that this token
     * exists before reading it. Additonally, it not being present is not
     * explicitly an error - it may be expected. */
    return LOSClient[tokenKey];
  },
  logOut: async (shouldRedirect = true) => {
    const auth = await getAuth();
    auth.signOut();
    document.cookie = "";
    localStorage.clear();
    sessionStorage.clear();
    const url = new URL(window.location.href);
    url.searchParams.delete("id");
    if (shouldRedirect) document.location = url.href;
  },
};
const LOSClientMethods = {
  init: initializePage,
  usStates: getStates,
  user: {
    get: getUser,
    init: initUser,
    register: registerUser,
    createAddress,
    updateDriversLicense,
    updateAddressDetails,
    updatePhone,
    updateNameAndDob,
    updateAttribution,
    updateSsn,
    verifyWithCode,
    resendCode,
    incomeVerification: {
      getPlaidToken,
      startSession,
      endSession,
    },
    updateEmail,
    verifyEmail,
    updateReferrer,
    experiments: {
      add: addUserToExperiment,
      track: trackExperimentEvent,
    },
    budget: {
      track: trackBudgetUser,
    },
  },
  tasks: {
    DEPRECATEDCompleteDocusign,
    setTaskAsPending,
    setTasksToFinalized,
  },
  document: {
    getUploadLink,
    getDocuments,
    updateUploaded,
    createOptionalUploadLink,
    createTaskDocUploadLink,
  },
  offer: {
    build: buildOffer,
  },
  applicant: {
    get: getApplicant,
    updateApplicantNameAndDob,
    verifyIdentity,
  },
  vehicle: {
    get: getVehicle,
    getBasic: getVehicleBasic,
    compareVins,
  },
  application: {
    get: getApplication,
    getAll: getApplications,
    confirmOffer,
    updateTerms,
    selectDirectOffer,
    createDraft,
    createApplication,
    selectRefiOffer,
    getSelectedOffer,
    getHardCredit,
    getSoftCredit,
    getDocusignLink,
    getOffers,
    getLimits,
    getPrequal,
    payments: {
      createAutopay,
      getAutopay,
      postAutopayPreference,
      updateAutopay,
      cancelAutopay,
    },
    emailOffer,
    cancelApplication,
    updateApplicant,
    createDirectOffer,
    postDealershipInfo,
    getDealershipInfo,
    updateDealershipInfo,
    vehicle: {
      create: createVehicle,
      update: updateVehicle,
      upsertPrevLoan,
    },
    chargerInstall: {
      createEstimate: createChargerEstimate,
      postChargerInstall: postChargerInstallDecision,
    },
    charger: {
      postCharger: postChargerDecision,
      createChargerPricing,
    },
    getAddons,
    getChargerEligibility,
    postWarranty,
    postGAP,
    postPrelimGAP,
    updateUnassociatedVehicle,
    postWaitlist,
    getTasks,
  },
  business: {
    get: getBusiness,
    upsertInfo: upsertBusinessInfo,
    upsertOwnership: upsertBusinessOwnership,
  },
  states: {
    addEmailToWaitList,
  },
  models: {
    getAllVehicleProfilesForMake,
    search,
    isFinanceSupported,
    getAllVehicleMakes,
  },
  tracking: {
    post: trackEvent,
    trackOfferConfirmed,
    subscribeEmail,
  },
  calculator: {
    get: getCalculatorConfig,
  },
};

const LOSClient = {
  ...LOSClientCommon,
  ...LOSClientMethods,
};

export default LOSClient;

export type APIClient = typeof LOSClient;

if (typeof window !== "undefined" && stack !== "prod" && stack !== undefined) {
  /* MPR, 2022/8/19: I know this looks weird but trust me, its very useful
   * to be able to directly access the client in non prod contexts. It's not
   * inherently dangerous to have it in prod either, (security through
   * obscurity is inherently flawed, particularly in the FE), but defence-
   * in-depth _does_ include not making reverse engineering easier than
   * it needs to be. */
  /* eslint-disable */
  /* @ts-ignore */
  window.tenet = window.tenet || {};
  /* eslint-disable */
  /* @ts-ignore */
  window.tenet.api = window.tenet.api || {};
  /* eslint-disable */
  /* @ts-ignore */
  window.tenet.api.los = LOSClient;
  /* @ts-ignore */
  window.tenet.api.los.login = async (email: string, password: string) => {
    const auth = await getAuth();
    const firebaseAuth = await import("firebase/auth");
    await firebaseAuth.signInWithEmailAndPassword(auth, email, password);
  };
  /* eslint-enable */
}
